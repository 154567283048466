<template>
  <div class="foor_container">
    <div class="page_content fs16">
      <p class="mb20">联系方式：</p>
      <div class="info_box grid fs14">
        <div class="maps grid">
          <div class="map_item grid" v-for="map in maps" :key="map.title">
            <img :src="map.mapImg" alt="">
            <div class="info">
              <p>{{map.title}}</p>
              <p>{{map.address}}</p>
              <p>{{map.call}}</p>
            </div>
          </div>
        </div>

        <div class="codes grid">
          <div class="code grid_c" v-for="code in codes" :key="code.title">
            <img :src="code.codeImg" alt="">
            <p>{{code.title}}</p>
          </div>
        </div>
      </div>
      <p class="tac">Copyright © 医赋知识（北京）科技有限公司 |
        <sapn class="icp_code hand" @click="toIcp">京ICP备17072089号-5</sapn>
      </p>
    </div>
  </div>
</template>

<script setup>
const maps = [
  {
    mapImg: 'https://img.infox-med.com/webImg/harvardAvenue/home/chinaMap.png',
    title: '中国总部',
    address: '北京市朝阳区东三环北路丙2号7层A805',
    call: '400-882-3548'
  }, {
    mapImg: 'https://img.infox-med.com/webImg/harvardAvenue/home/americaMap.png',
    title: '美国总部',
    address: '1 Harvard Street,Brookline,MA, 02445',
    call: '(01)617-716-6228'
  }
]
const codes = [
  {
    codeImg: 'https://img.infox-med.com/webImg/harvardAvenue/public/harvard_code.png',
    title: '哈佛一号研究院扫码关注'
  }, {
    codeImg: 'https://img.infox-med.com/webImg/harvardAvenue/public/msyl_code.png',
    title: '麻省医疗国际扫码关注'
  }
]

const toIcp = () => {
  window.open('https://beian.miit.gov.cn/#/Integrated/index')
}
</script>

<style lang="scss" scoped>
.foor_container {
  height: 256px;
  background-color: #000;
  color: #fff;
  .page_content {
    padding-top: 20px;
  }
  .grid {
    display: grid;
    align-items: center;
    justify-content: space-between;
  }
  .info_box {
    grid-template-columns: repeat(2, auto);
    column-gap: 30px;
    margin-bottom: 30px;
  }
  .maps {
    grid-template-columns: repeat(2, auto);
    column-gap: 82px;
  }
  .map_item {
    grid-template-columns: 128px auto;
    column-gap: 10px;
    img {
      width: 128px;
      height: 100px;
      object-fit: cover;
    }
  }
  .codes {
    grid-template-columns: repeat(2, auto);
    column-gap: 20px;
    img {
      width: 66px;
      height: 66px;
      margin-bottom: 12px;
    }
  }
}
.icp_code {
  color: #fff;
}
</style>

import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '@/views/home/index.vue'

const routes = [
  {
    path: '/',
    component: HomeView
  }, {
    path: '/fromBenchToBusiness',
    component: () => import('@/views/fromBenchToBusiness/index.vue')
  }, {
    path: '/continuingEducation',
    component: () => import('@/views/continuingEducation/index.vue')
  }, {
    path: '/scholarsForum',
    component: () => import('@/views/scholarsForum/index.vue')
  }, {
    path: '/aboutUs',
    component: () => import('@/views/aboutUs/index.vue')
  },

]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    // 始终返回顶部
    return { top: 0 };
  }
})

export default router

<template>
  <div class="menu_container">
    <div class="menu_item hand" v-for="item in menuList" :key="item.title"
         :class="{'activate_menu':$route.path=== item.path}" @click="toPath(item)">
      <p class="tac menu_title_zh">{{item.title}}</p>
      <p class="tac menu_title_ch">{{item.titleCn}}</p>
      <i class="line"></i>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue"
import { useRoute, useRouter } from "vue-router"
const $route = useRoute()
const $router = useRouter()
const menuList = [
  {
    title: '首页',
    titleCn: 'Home',
    path: '/'
  }, {
    title: '科研转化',
    titleCn: 'From Bench To Business',
    path: '/fromBenchToBusiness'
  }, {
    title: '医疗进修',
    titleCn: 'Continuing Education',
    path: '/continuingEducation'
  }, {
    title: '学者论坛',
    titleCn: 'Scholars Forum',
    path: '/scholarsForum'
  }, {
    title: '关于我们',
    titleCn: 'About Us',
    path: '/aboutUs'
  }
]

const toPath = (item) => {
  if (item.path === $route.path) return
  $router.push(item.path)
}

</script>

<style lang="scss" scoped>
.menu_container {
  display: grid;
  grid-template-columns: repeat(5, auto);
  align-items: center;
  column-gap: 55px;
  height: 100%;
  justify-items: center;
}
.menu_item {
  position: relative;
  padding: 0 5px;
  font-weight: 600;
  &:hover {
    .line {
      width: 80%;
    }
  }
  .menu_title_zh {
    font-size: 16px;
  }
  .menu_title_ch {
    font-size: 12px;
    color: #666666;
  }
  .line {
    position: absolute;
    left: 50%;
    bottom: -6px;
    height: 3px;
    width: 0%;
    border-radius: 3px;
    background-color: #9f1f2e;
    transform: translate(-50%, 0);
    transition: width 0.3s;
  }
}
.activate_menu {
  color: #9f1f2e;

  .menu_title_ch {
    color: #9f1f2e;
  }
  .line {
    display: none;
  }
}
</style>

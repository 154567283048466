<template>
  <div class="home ">
    <div class="banner">
      <img class="banner_bg" src="https://img.infox-med.com/webImg/harvardAvenue/home/homeCover.jpg"
           alt="">
      <div class="page_content">
        <img src="https://img.infox-med.com/webImg/harvardAvenue/home/home_slogan.png" alt=""
             class="home_slogan">

      </div>
    </div>

    <div class="platform">
      <CardTitle title="我们的平台" titleCn="OUR  PL ATFORMS" />
      <div class="platform_card_list page_content">
        <div class="platform_itme" v-for="item in platformList" :key="item.title">
          <img class="item_bg" :src="item.img" alt="">
          <div class="title_box dfc">
            <p class="fs26 mb10">{{item.title}}</p>
            <p>{{item.titleCn}}</p>
          </div>
          <div class="info_card shadow">
            <div class="info_card_content ">
              <div class="subtitle">{{item.subtitle}}</div>
              <p class="describe">{{item.describe}}</p>
              <p class="describe">{{item.describeCn}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="card" v-for="(card,i) in cardList" :key="card.cardTitle">
      <CardTitle :title="card.cardTitle" :titleCn="card.cardTitleCn" />
      <div class="card_content" :class="{'end_img': i===1}">
        <img :src="card.imgBg" alt="" class="card_img">
        <div class="page_content">
          <div class="info_content shadow">
            <div class="info">
              <p class="describe">{{card.describe}}</p>
              <p>{{card.describeCn}}</p>
            </div>

          </div>
        </div>

      </div>

    </div>

    <div class="scholarsCard">
      <CardTitle title="学者论坛" titleCn="Scholars Forum" />
      <div class="card_content">
        <img :src="card3.imgUrl" class="scholarsCard_img" alt="">
        <div class="scholarsCard_infobox">
          <div class="scholarsCard_describe">
            <p>{{card3.describe}}</p>
          </div>
          <p>{{card3.describeCn}}</p>
        </div>

      </div>
    </div>
    <div class="mapCard">
      <CardTitle title="企业全球布局" titleCn="Global layout" />
      <div class="mapCard_content">
        <img src="https://img.infox-med.com/webImg/harvardAvenue/home/homeMap.png"
             class="mapCard_img" alt="">
      </div>
    </div>
  </div>
</template>

<script setup>
import CardTitle from '@/components/cardTitle/index.vue'

const platformList = [
  {
    img: 'https://img.infox-med.com/webImg/harvardAvenue/home/homeList1.png',
    title: '< 科研转化 >',
    titleCn: 'From Bench To Business',
    subtitle: '科研转化 (From Bench To Business)',
    describe: '打造全方位资源共享平台，为实现从科研到商业的转化提供一站式解决方案。',
    describeCn: 'A full range of resource sharing platforms to enable transformation from bench to business.'
  }, {
    img: 'https://img.infox-med.com/webImg/harvardAvenue/home/homeList2.png',
    title: '< 医疗进修 >',
    titleCn: 'Continuing Education',
    subtitle: '医疗进修 (Continuing Education)',
    describe: '推动高层次临床医学人才培养，促进中美医疗卫生人才培养领 域交流合作。',
    describeCn: 'Further promote high-level training of clinicians and facilitate the exchange and cooperation in all healthcare fields.'
  }, {
    img: 'https://img.infox-med.com/webImg/harvardAvenue/home/homeList3.png',
    title: '< 学者论坛 >',
    titleCn: 'Scholars Forum',
    subtitle: '学者论坛 (Scholars Forum)',
    describe: '卓越思想汇聚，共享最优资源，构建线上和线下中国学子海外家园。',
    describeCn: 'A salon for Chinese and U.S. healthcare professionals to share their inspiring thoughts and reflections on the rapidly changing medical landscape.'
  }
]

const cardList = [
  {
    imgBg: 'https://img.infox-med.com/webImg/harvardAvenue/home/homeCard1.png',
    cardTitle: '科研转化',
    cardTitleCn: 'From Bench to Business',
    describe: '哈佛大道一号研究院依托麻省医疗国际的业务资源，广泛链接全美顶尖实验室，研究队伍和商业资源，为生命科学领域的研究人员提供资源对接，深度咨询，信息支持等一站式解决方案，打造全方位资源共享平台，帮助实现从科研到商业的转化。',
    describeCn: "One Harvard Street Institute relies  on Mass Medical International's business resources, extensive links to the nation's top laboratories, research teams and commercial resources for  life science researchers to provide resources such as docking, consulting , one-stop solutions, and create a full range of resource sharing platforms to help transform  research to business."
  }, {
    imgBg: 'https://img.infox-med.com/webImg/harvardAvenue/home/homeCard2.png',
    cardTitle: '医疗进修',
    cardTitleCn: 'Continuing Education',
    describe: '为满足中国医疗卫生领域的发展需求，哈佛大道一号研究院与包括哈佛附属医院在内的全美各大顶尖医院/医学院合作，携手打造针对中国医生的多个医学交流进修项目，旨在进一步推动高层次临床医学人才培养工作，促进中美在医疗卫生人才培养领域的交流与合作。',
    describeCn: "To meet the development needs of the Chinese healthcare sector, One Harvard Street Institute works with a number of top-echelon hospitals  and medical schools to create a number of medical exchange programs for Chinese medical professionals. We aim to further promote high-level training of clinicians , and promote the exchange and cooperation between China  and the United States"
  }
]

const card3 = {
  imgUrl: 'https://img.infox-med.com/webImg/harvardAvenue/home/homeCard3.png',
  describe: '哈佛大道一号研究院学者论坛是卓越思想的汇聚地。定期举办的专题报告，学术研讨，领导力峰会等活动，紧贴国际医疗健康前沿热点，促进中美医疗人员学者的交流，共享最优资源。构建线上和线下的中国学子海外家园， 为在海外的中国医疗健康同仁构建起友谊的桥梁。',
  describeCn: 'One Harvard University Research Institute is a gathering place for excellence. We regularly hold academic seminars, leadership summits and other events to share scholars’ inspiring insights. In Longwood area, we are in close proximity to the nexus of medical experimentation.  We strive to tap into this energy and promote exchanges between Chinese and US medical personnel and scholars.'
}
</script>

<style lang="scss" scoped>
.banner {
  height: 518px;
  position: relative;
  .banner_bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .page_content {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, 0);
  }
  .home_slogan {
    position: absolute;
    top: 107px;
    left: 80px;
    width: 553px;
    height: 204px;
  }
}

.platform {
  background-color: #f5f7fb;
}
.shadow {
  position: relative;
  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: -4px;
    width: 100%;
    height: 100%;
    z-index: 90;
    background-color: #d8d8d8;
    border-radius: 8px;
  }
}
.platform_card_list {
  display: grid;
  grid-template-columns: repeat(3, 32.222%);
  align-items: center;
  justify-content: space-between;
  padding-bottom: 30px;

  .platform_itme {
    position: relative;
    width: 100%;
    .item_bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
    }
    .title_box {
      position: relative;
      z-index: 99;
      width: 100%;
      height: 185px;
      flex-direction: column;
      color: #ffffff;
      font-weight: 600;
    }
    .platform_card_title {
    }
    .info_card {
      position: relative;
      z-index: 99;
      height: 214px;

      // background-color: #fff;
      // box-shadow: 0 0 0 4px #d8d8d8;

      .info_card_content {
        position: relative;
        height: 100%;
        z-index: 99;
        border-radius: 8px;
        background-color: #fff;
        padding: 30px 30px 0 30px;
        line-height: 24px;
        .subtitle {
          font-size: 16px;
          margin-bottom: 10px;
        }
        .describe {
          color: #666;
        }
      }
    }
  }
}

.card {
  padding-bottom: 30px;
  background-color: #fff;

  .info {
    position: relative;
    z-index: 99;
    background-color: #9f1f2e;
    color: #fff;
  }
  .card_content {
    position: relative;
    text-align: start;
    .card_img {
      display: inline-block;
      width: 60.57%;
    }
  }
  .end_img {
    text-align: end;
  }

  .page_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    // height: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    text-align: start;
  }
  .info {
    width: 970px;
    padding: 30px 60px;
    border-radius: 10px;
    line-height: 28px;
    font-size: 14px;
    .describe {
      padding-bottom: 25px;
      margin-bottom: 25px;
      border-bottom: 1px solid #fff;
    }
  }

  &:nth-child(2n) {
    background-color: #f5f7fb;
    .info {
      background-color: #fff;
      color: #000;
      .describe {
        border-bottom-color: #000;
      }
    }
    .card_img {
      right: 0;
    }
  }
  &:nth-child(2n-1) {
    .card_img {
      left: 0;
    }
    .page_content {
      justify-content: flex-end;
    }
  }
}

.scholarsCard {
  padding-bottom: 30px;
  .card_content {
    position: relative;
    // height: 450px;
    background-color: #9f1f2e;
    color: #fff;
    font-size: 14px;
    line-height: 2em;
    .scholarsCard_img {
      position: relative;
      width: 51.5625%;
      object-fit: cover;
      border-radius: 0 10px 10px 0;
      z-index: 99;
    }
    .scholarsCard_infobox {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(0, -50%);
      width: 50%;
      // height: 100%;
      // background-color: #666;
      padding: 0 3.125% 0 6.25%;

      p {
        width: 510px;
      }
      .scholarsCard_describe {
        position: relative;
        padding-bottom: 25px;
        margin-bottom: 25px;
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: -120px;
          width: calc(100% + 120px);
          height: 1px;
          background-color: #fff;
        }
      }
    }
  }
}

.mapCard {
  background-color: #f5f7fb;
}
.mapCard_content {
  padding-bottom: 50px;
  text-align: center;
  .mapCard_img {
    display: inline-block;
    width: 1080px;
    height: 649px;
    object-fit: cover;
  }
}
</style>
